class ContactModel{

	sendMessage = async (data) => {
	  const url = '/kontakt/send-message';
      const response = await axios.post(url, data, {
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }});       
        return response.data;
    }
}

export const Contact = new ContactModel();