import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ContactCardView } from '../Views/ContactCardView';
import { Contact } from '../Models/Contact';

export function ContactCard(props){

	const user = JSON.parse(props.user);
	const [state, setState] = useState({
		email: user.email,
		message: '',
	})

	const handleChange = e => {
		e.preventDefault();
		e.persist();
		setState(prevState => ({
			...prevState,
			[e.target.name] : e.target.value
		}))
	}

	const sendMessage = e => {
		e.preventDefault();
		e.persist();
		Contact.sendMessage(state).then(response => {
			setState(prevState => ({
				...prevState,
				message: '',
			}))
			updateFlashMessage('success', response.message)
			setTimeout(() => { updateFlashMessage('reset') }, 3000);
		}).catch(err => err)
	}

	// flash message
	const [flashMessage, setFlashMessage] = useState({
		type: '',
		value: ''
	})
	const updateFlashMessage = (type = '', value = '') => {
		// reset
		setFlashMessage({
			type: '',
			value: ''
		})
		if(type == 'reset'){
			return;
		}
		// set new values
		setFlashMessage({
			type: type,
			value: value
		})
	}

	return(
		<ContactCardView
		state={state}
		sendMessage={sendMessage}
		handleChange={handleChange}
		flashMessage={flashMessage}
		/>
	)
}

if (document.getElementById('contact-card')) {
	const user = document.querySelector('#contact-card').getAttribute('data-user');
    ReactDOM.render(<ContactCard user={user} />, document.getElementById('contact-card'));
}