import React, { Fragment } from 'react';

export function ContactCardView(props){

	const { state, sendMessage, handleChange, flashMessage } = props;

	return(
		<Fragment>
			<h2 className="lead-text text-center mb-2">Zapraszamy do kontaktu</h2>

			<form onSubmit={sendMessage}>
			<div className="mb-3">
                <label htmlFor="email" className="col-md-4 col-form-label">Adres e-mail</label>
                <input id="email" type="email" className="form-control" onChange={()=>{return}} name="email" value={state.email} disabled />
            </div>

            <div className="mb-3">
                <label htmlFor="message" className="col-md-4 col-form-label">Treść wiadomości
                  <span className={"flash-message "+flashMessage.type}>{flashMessage.value}</span>
                </label>
                <textarea onChange={handleChange} name="message" className="form-control" cols="20" rows="7" value={state.message}></textarea>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="accept" required />
               <label className="form-check-label" htmlFor="accept">
                Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                F2F Evolution Marek Kustosz, 02-797 Warszawa, Aleja Komisji 
                Edukacji Narodowej 55 lok. 91 (dalej „F2F"), jako administratora
                danych zgodnie z ogólnym rozporządzeniem o ochronie danych
                osobowych („RODO"). Podane przez Państwa dane będą przetwarzane
                przez F2F w celach wskazanych w treści zgody. Dane będą przechowywane
                do czasu wycofania zgody. Każda osoba ma prawo dostępu do swoich
                danych, ich poprawiania i uzupełniania oraz w zakresie wynikającym
                z przepisów – do usunięcia, przeniesienia, ograniczenia 
                przetwarzania, wniesienia sprzeciwu wobec ich przetwarzania
                oraz prawo do wniesienia skargi do Prezesa Urzędu Ochrony 
                Danych Osobowych. Kontakt w zakresie przetwarzania danych: <a target="_blank" 
                href="mailto:kontakt@przypadkikliniczne.edu.pl">kontakt@przypadkikliniczne.edu.pl</a> lub korespondencyjnie.
              </label>
            </div>

            <button type="submit" className="btn btn-primary w-100 mt-1">
                Wyślij
            </button>

            </form>

        </Fragment>
	)
}