require('summernote/dist/summernote-bs5.css');
require('summernote/dist/summernote-bs5.js');
require('summernote/dist/lang/summernote-pl-PL.js');

$(document).ready(function() {
  $('#summernote').summernote({
    lang: 'pl-PL',
    height: 350,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontsize']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link', 'picture', 'hr']],
      ['view', ['codeview']],
    ],

    callbacks: {
        onInit: function() {
            $("button.dropdown-toggle").on('click', function (e) {
                handleDropDown(e);
            });
        }
    }

  });
});

const handleDropDown = (e) => {
  if( e.target.parentNode.classList.contains('open') ){
    e.target.parentNode.classList.remove('open')
  } else {
    e.target.parentNode.classList.add('open')
  }
}