import { Modal } from 'bootstrap';
///==============================
handleBtnAllClick();
handleHamburgerOpen();
handleHamburgerClose();
toggleInputPasswordHidden();
makeValuesFromCheckBoxLabelsWithRegistration();
showResetPasswordModal();
cookies();

///==============================

function handleBtnAllClick(){
	const btnAll = document.querySelector('input#all')
	if(!btnAll){
		return
	}
	btnAll.addEventListener('click', function(e){
		if(e.target.checked){
			document.querySelectorAll('input.form-check-input').forEach(el => {
				el.checked = true;
			});
		} else {
			document.querySelectorAll('input.form-check-input').forEach(el => {
				el.checked = false;
			});
		}
	})
}

function handleHamburgerOpen(){
	const hambBtn = document.querySelector('button#navbar-open')
	if(!hambBtn){
		return
	}
	hambBtn.addEventListener('click', function(e){
		const navbarUl = document.querySelector('.navbar > .nav-ul-wrapper');
		const closeBtn = document.querySelector('button#navbar-close');
		navbarUl.classList.add('sm-show');
		closeBtn.classList.add('show');
	})
}

function handleHamburgerClose(){
	const closeBtn = document.querySelector('button#navbar-close');
	const navbarUl = document.querySelector('.navbar > .nav-ul-wrapper');
	if(!closeBtn){
		return;
	}
	closeBtn.addEventListener('click', function(e){
		navbarUl.classList.remove('sm-show');
		closeBtn.classList.remove('show');
	});
}

function toggleInputPasswordHidden(){
	const btns = document.querySelectorAll('span.show-hide-password');
	if(!btns){
		return
	}
	[...btns].map(btn => {
		btn.addEventListener('click', function(e){
			e.target.classList.toggle('password-hidden');
			const input = e.target.parentNode.querySelector('input');
			const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
            input.setAttribute('type', type);
		})
	})

}

function makeValuesFromCheckBoxLabelsWithRegistration(){
	const checkBoxes = document.querySelectorAll('#register-form .form-check');
	if(checkBoxes.length == 0){
		return
	}
	checkBoxes.forEach(el => {
		const label = el.querySelector('label') && el.querySelector('label').textContent;
		if(label){
			el.querySelector('input').value = label;
		}
	})
}

function showResetPasswordModal()
{
	const btn = document.querySelector('#reset-password-link')
	if(!btn){
		return
	}
	btn.addEventListener('click', function(e){
		const modal = new Modal(document.querySelector('#resetPasswordModal'))
		modal.show()
	})
}

function cookies(){
	function setCookie(cname,cvalue,exdays) {
	   var d = new Date();
	   d.setTime(d.getTime() + (exdays*24*60*60*1000));
	   var expires = "expires=" + d.toGMTString();
	   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
	 const decodedCookie = document.cookie
	 const ca = decodedCookie.split(';').filter(el => el.includes(cname));
	   if(ca.length == 0){
	      return false;
	   }
	 const cvalue = ca[0].split('=')[1];
	 return cvalue;
	}

	if(getCookie('cookiesmsg') != 'hide')
  	{
    	document.querySelector('.cookies-wrapper').classList.add('show');
  	}

	const btn = document.querySelector('#cookies-accept');

	btn.addEventListener('click', function(e){
  		e.preventDefault();
  		setCookie('cookiesmsg', 'hide', 30);
  		document.querySelector('.cookies-wrapper').classList.remove('show');
	})
}
