import React, { Fragment } from 'react';
import Select from 'react-select';

export function MyAccountView(props){

	const { flashMessage, handleChange, inputs, toggleDisabled, logout, setView, deleteAccountModal } = props;

	const changePasswordView = (e) => {
		e.preventDefault();
		setView('change_password')
	}

    const selectChange = (value, options) => {
        const data = {
            type: 'react-select',
            name: options.name,
            value: value.value,
        }
        handleChange(data)
    }


    ///set up spec list and workplaces from the backend
    const specList = JSON.parse( document.querySelector('input[name=spec_list]').getAttribute('data-spec') );
    const workPlaces = JSON.parse( document.querySelector('input[name=spec_list]').getAttribute('data-workplaces') );

    let s_list = [];
    specList.forEach(el => {
        s_list.push({
            value:el,
            label: el,
        })
    })

    let w_list = [];
    workPlaces.forEach(el => {
        w_list.push({
            value:el,
            label: el,
        })
    })
    ///==============================

	return(
		<Fragment>
			<h2 className="text-center mb-4">Moje konto</h2>

            <div className="mb-3 input-flex">
                <label htmlFor="email" className="col-md-4 col-form-label">E-mail
                	<span className={"flash-message "+flashMessage.email.type}>{flashMessage.email.value}</span>
                </label>
                	<div className="d-flex">
                		<input onChange={handleChange} id="email" type="email" className="form-control" name="email"
                		value={inputs.email.value} disabled={inputs.email.disabled}/>
                		<a href="##" onClick={toggleDisabled} data-name="email"
                		className="text-green text-decoration-none">{inputs.email.disabled?'Edytuj':'Zapisz'}</a>
                	</div>
            </div>

            <div className="mb-3 input-flex">
                <label htmlFor="specialization" className="col-md-4 col-form-label">Specjalizacja
                <span className={"flash-message "+flashMessage.specialization.type}>{flashMessage.specialization.value}</span>
                </label>
                <Select
                    className='react-select'
                    value={s_list.filter(el => el.value == inputs.specialization.value)[0]}
                    options={s_list}
                    noOptionsMessage={() => 'Brak wyników'}
                    onChange={selectChange}
                    placeholder={'Wybierz'}
                    name="specialization"
                    components={{
                        IndicatorSeparator: () => null
                      }}
                />
            </div>

            <div className="mb-3 input-flex">
                <label htmlFor="workplace" className="col-md-4 col-form-label">Miejsce pracy
                <span className={"flash-message "+flashMessage.workplace.type}>{flashMessage.workplace.value}</span>
                </label>
                <Select
                    className='react-select'
                    value={w_list.filter(el => el.value == inputs.workplace.value)[0]}
                    options={w_list}
                    noOptionsMessage={() => 'Brak wyników'}
                    onChange={selectChange}
                    placeholder={'Wybierz'}
                    name="workplace"
                    components={{
                        IndicatorSeparator: () => null
                      }}
                />
            </div>

            <div className="mb-3 link-flex">
            	<a onClick={changePasswordView} href="##">
                	<div className="d-flex justify-content-between">
                		<span>Zmień hasło</span>
                		<span><i className="arrow-right"></i></span>
                	</div>
                </a>
            </div>

            <div className="mb-3 link-flex">
            	<a href="##" onClick={logout}>
                	<div className="d-flex justify-content-between">
                		<span>Wyloguj się</span>
                		<span><i className="arrow-right"></i></span>
                	</div>
                </a>
            </div>

            <div className="mb-3 link-flex">
            	<a onClick={deleteAccountModal} href="##">
                	<div className="d-flex justify-content-between">
                		<span>Usuń konto</span>
                		<span><i className="arrow-right"></i></span>
                	</div>
                </a>
            </div>
        </Fragment>
	)
}