import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';
import { User } from '../Models/User';
import { MyAccountView } from '../Views/MyAccountView';
import { ChangePasswordView } from '../Views/ChangePasswordView';
import { Modal } from 'bootstrap';

export function MyAccount(props)
{	
	const user = JSON.parse(props.user);
	const logout = (e) => {
		e.preventDefault();
		document.getElementById('logout-form').submit();
	}

	const [inputs, setInputs] = useState({
		email: {
			value: user.email,
			disabled: true,
		},
		specialization: {
			value: user.specialization,
			disabled: true,
		},
		workplace: {
			value: user.workplace,
			disabled: true,
		},
	})

	const handleChange = (e) => {

		if(e.type == 'react-select'){
			setInputs(prevInputs => ({
			  ...prevInputs,
			  [e.name]: {
			    ...prevInputs[e.name],
			    value: [e.value],
			    disabled: false,
			  }
			}))

			return;
		}

		e.persist();
		setInputs(prevInputs => ({
		  ...prevInputs,
		  [e.target.name]: {
		    ...prevInputs[e.target.name],
		    value: [e.target.value]
		  }
		}))

		if(e.target.name == 'workplace'){
			setInputs(prevInputs => ({
			  ...prevInputs,
			  [e.target.name]: {
			    ...prevInputs[e.target.name],
			    value: [e.target.value],
			    disabled: false,
			  }
			}))
		}
	}

	useEffect(() => {
		if(inputs.workplace.disabled == false){
			changeUserData('workplace')
			setInputs(prevState => ({
				...prevState,
				workplace: {
					...prevState.workplace,
					disabled:true
				}
			}))
		}

		if(inputs.specialization.disabled == false){
			changeUserData('specialization')
			setInputs(prevState => ({
				...prevState,
				specialization: {
					...prevState.specialization,
					disabled:true
				}
			}))
		}
	}, [inputs])

	const toggleDisabled = (e) => {
		e.preventDefault();
		const inputName = e.target.getAttribute('data-name');
		// set all to true
		setInputs(prevInputs => ({
		  	email: {
				...prevInputs.email,
				disabled: true,
			},
			specialization: {
				...prevInputs.specialization,
				disabled: true,
			},
			workplace: {
				...prevInputs.workplace,
				disabled: true,
			},
		}))

		setInputs(prevInputs => ({
		  ...prevInputs,
		  [inputName]: { 
		    ...prevInputs[inputName],
		    disabled: false
		  }
		}))
		if(e.target.innerHTML == 'Zapisz'){
			///set disabled again
			setInputs(prevInputs => ({
			  ...prevInputs,
			  [inputName]: { 
			    ...prevInputs[inputName],
			    disabled: true
			  }
			}))
			///change data on backend
			changeUserData(inputName)
		}

		///set label
		if(e.target.innerHTML == 'Edytuj')
		{
			e.target.innerHTML = 'Zapisz';
		} else {
			e.target.innerHTML = 'Edytuj';
		}
	}

	const changeUserData = (inputName) => {
		User.updateData(inputs).then(response => {
			//flash message
			updateFlashMessage(inputName, 'success', response.message)
			setTimeout(() => { updateFlashMessage('reset') }, 1500);
		}).catch(err => {
			//flash message
			updateFlashMessage(inputName, 'error', err.response.data.message)
			setTimeout(() => { updateFlashMessage('reset') }, 1500);
		})
	}

	// flash message 
	const [flashMessage, setFlashMessage] = useState({
		email: {
			type: '',
			value: ''
		},
		specialization: {
			type: '',
			value: ''
		},
		workplace: {
			type: '',
			value: ''
		}
	})
	const updateFlashMessage = (inputName, type = '', value = '') => {
		// reset
		setFlashMessage({
			email: {
				type: '',
				value: ''
			},
			specialization: {
				type: '',
				value: ''
			},
			workplace: {
				type: '',
				value: ''
			}
		})
		if(inputName == 'reset'){
			return;
		}
		// set new values
		setFlashMessage(prevInputs => ({
		  ...prevInputs,
		  [inputName]: { 
		    type: type,
		    value: value
		  }
		}))
	}

	//my account views change
	const [view, setView] = useState(
		'my_account' //change_password
		)

	// delete account modal and logic
	const deleteAccountModal = (e) => {
		e.preventDefault();
		const modal = new Modal(document.querySelector('#deleteAccountModal'))
		modal.show()
	}

	return(
		<Fragment>
			{view == 'my_account' && <MyAccountView flashMessage={flashMessage} 
			handleChange={handleChange} inputs={inputs} deleteAccountModal={deleteAccountModal}
			toggleDisabled={toggleDisabled} logout={logout} setView={setView} />}
			{view == 'change_password' && <ChangePasswordView setView={setView} />}
		</Fragment>)
}


if (document.getElementById('my-account-card')) {
	const user = document.querySelector('#my-account-card').getAttribute('data-user');
    ReactDOM.render(<MyAccount user={user} />, document.getElementById('my-account-card'));
}

//==========================================================================
export function DeleteAccountModalFooter() {

	const closeBtn = useRef(null);

	const deleteAccount = e => {
		e.preventDefault();
		closeBtn.current.click();
		User.deleteAccount().then(response => {
			const modal2 = new Modal(document.querySelector('#deleteAccountConfirmation'));
			modal2.show();
		}).catch(err => err)
	}

	return(
		<Fragment>
			<button onClick={deleteAccount} type="button" className="btn btn-primary">Usuń konto</button>
        	<button ref={closeBtn} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Anuluj</button>
		</Fragment>
	)
}

if (document.getElementById('deleteAccountModalFooter')) {
    ReactDOM.render(<DeleteAccountModalFooter />, document.getElementById('deleteAccountModalFooter'));
}