class UserModel{

	updateData = async (data) => {
	  const url = '/moje-konto';
      const response = await axios.post(url, data, {
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }});
        return response.data;
    }

  updatePassword = async(data) => {
    const url = '/moje-konto/update-password';
      const response = await axios.post(url, data, {
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }});
        return response.data;
  }

  deleteAccount = async(data) => {
    const url = '/moje-konto/delete-account';
      const response = await axios.post(url, data, {
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }});
        return response.data;
  }

  resetPassword = async(data) => {
    const url = '/password/email';
      const response = await axios.post(url, data, {
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }});
        return response.data;
  }
}

export const User = new UserModel();