require('./bootstrap');

///react
require('./components/Controllers/MyAccountController');
require('./components/Controllers/ContactController');
require('./components/Controllers/ResetPasswordController');

//plugins
require('./summernote');

//additional scripts
require('./additional');

//select2
require('./select2/select2.min.css');
require('./select2/select2_and_react_select_custom.css');
require('./select2/select2.min');
$(document).ready(function() {
    $('.select2').select2({
	  "language": {
	       "noResults": function(){
	           return "Brak wyników.";
	       }
	   },
	});
});