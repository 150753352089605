import React, { Fragment, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { User } from '../Models/User';
import { Modal } from 'bootstrap';

export function ResetPassword(){

	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const closeBtn = useRef(null);

	const emailChange = e => {
		setEmail(e.target.value)
	}

	const passwordUpdate = (e) => {
		e.preventDefault();
		if(email.length < 1){
			setError('Wprowadź e-mail');
			return;
		}
		User.resetPassword({email}).then(response => {
			closeBtn.current.click();
			const modalConf = new Modal(document.querySelector('#passwordConfirmationModal'))
			modalConf.show()
		}).catch(err => {
			setError(err.response.data.errors.email[0])
		})
	}

	return(
		<Fragment>
		<div className="modal-body">
	        <p>
	          Nie pamiętasz hasła? Podaj nam swój adres mailowy, a prześlemy Ci łącze umożliwiające zresetowanie hasła.
	        </p>
	        <div className="mb-1 text-start">
	          <label htmlFor="email-password" className="col-md-4 col-form-label">Adres e-mail</label>
	            <input id="email-password" type="email" onChange={emailChange}
	            className={"form-control"+(error.length?' is-invalid':'')}
	            name="email" value={email} autoComplete="email" autoFocus />
	            {error.length > 0 &&
                <span className="invalid-feedback" role="alert">
                    <strong>{error}</strong>
                </span>
                }
	        </div>
	    </div>
	    <div className="modal-footer">
			<button onClick={passwordUpdate} type="button" className="btn btn-primary">Wyślij</button>
          	<button ref={closeBtn} onClick={() => setEmail('')} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Anuluj</button>
        </div>
		</Fragment>
	)
}

if (document.getElementById('resetPasswordModalContent')) {
    ReactDOM.render(<ResetPassword />, document.getElementById('resetPasswordModalContent'));
}