import React, { Fragment, useState, useRef } from 'react';
import { User } from '../Models/User';

export function ChangePasswordView(props){

	const { setView } = props;

	const[state, setState] = useState({
		old_password: '',
		new_password: '',
		password_confirmation: ''
	})

	const handleChange = (e) => {
		e.preventDefault();
		e.persist();
		setState(prevState => ({
			...prevState,
			[e.target.name] : e.target.value
		}))
	}

	const backToMainView = (e) => {
		e.preventDefault();
		setView('my_account')
	}

	const clearState = (e) => {
		setState({
			old_password: '',
			new_password: '',
			password_confirmation: ''
		})
		backToMainView(e);
	}

	const updatePassword = (e) => {
		e.preventDefault();
		e.persist();
		User.updatePassword(state).then(response => {
			updateFlashMessage(response.name, 'success', response.message)
		}).catch(err => {
			updateFlashMessage(err.response.data.name, 'error', err.response.data.message)
		})

	}

	//validation responses
	const [flashMessage, setFlashMessage] = useState({
		old_password: {
			type: '',
			value: ''
		},
		new_password: {
			type: '',
			value: ''
		}
	})

	const updateFlashMessage = (inputName, type = '', value = '') => {
		setFlashMessage({
			old_password: {
				type: '',
				value: ''
			},
			new_password: {
				type: '',
				value: ''
			}
		})
		if(inputName == 'reset'){
			return;
		}
		setFlashMessage(prevState => ({
			...prevState,
			[inputName]: { 
			    type: type,
			    value: value
			  }
		}))
		setTimeout(() => { updateFlashMessage('reset') }, 1500);
	}

	return(
		<Fragment>
			<a onClick={backToMainView} href="##" className="heading-darkblue mb-4"><i className="arrow-left me-2"></i>Zmień hasło</a>

			<div className="mb-3 input-flex">
                <label htmlFor="old_password" className="col-md-4 col-form-label">Obecne hasło
                	<span className={"flash-message "+flashMessage.old_password.type}>{flashMessage.old_password.value}</span>
                </label>
        		<input onChange={handleChange} id="old_password" type="password" className="form-control" name="old_password"
        		value={state.old_password} />
            </div>

            <div className="mb-3 input-flex">
                <label htmlFor="new_password" className="col-md-4 col-form-label">Nowe hasło
                	<span className={"flash-message "+flashMessage.new_password.type}>{flashMessage.new_password.value}</span>
                </label>
        		<input onChange={handleChange} id="new_password" type="password" className="form-control" name="new_password"
        		value={state.new_password} />
            </div>

            <div className="mb-3 input-flex">
                <label htmlFor="password_confirmation" className="col-md-4 col-form-label">Potwierdź nowe hasło</label>
        		<input onChange={handleChange} id="password_confirmation" type="password" className="form-control" name="password_confirmation"
        		value={state.password_confirmation} />
            </div>

            <div className="row">
                <div className="col-md-6 mb-3">
                    <button onClick={updatePassword} type="submit" className="btn btn-primary w-100">
                        Zapisz
                    </button>
                </div>
                <div className="col-md-6 mb-3">
                    <button onClick={clearState} className="btn btn-outline-primary w-100">
                        Anuluj
                    </button>
                </div>
            </div>

		</Fragment>
		)
}